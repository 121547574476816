import React from "react";
import { LayoutBox } from "../../components/atoms/LayoutBox";
import Layout from "../../components/Layout";
import { TextBanner } from "../../components/molecules/TextBanner";

const CookiesNotice = () => {
    return (
        <Layout title="Cookies Notice">
            <TextBanner title="Cookies Notice" />
            <LayoutBox>
                <div className="content">
                    <p style={{testAlign: 'justify'}}>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and allows us to improve our site. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your device if you agree. Cookies contain information that is transferred to your device.</p>
                    <p style={{testAlign: 'justify'}}><strong>Use of Cookies&nbsp;</strong></p>
                    <p style={{testAlign: 'justify'}}><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website or make use of e-billing services.</p>
                    <p style={{testAlign: 'justify'}}><strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences, for example, your choice of language or region.</p>
                    <p style={{testAlign: 'justify'}}><strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users find what they are looking for easily.</p>
                    <p style={{testAlign: 'justify'}}><strong>Targeting cookies</strong>. These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p>
                    <p style={{testAlign: 'justify'}}><strong>Terminology to help you understand cookies&nbsp;</strong></p>
                    <p style={{testAlign: 'justify'}}><strong>First and Third-party cookies</strong></p>
                    <p style={{testAlign: 'justify'}}>First-party cookies are cookies set by our website, i.e. the website displayed in your URL window.&nbsp;</p>
                    <p style={{testAlign: 'justify'}}>Third-party cookies are cookies that are set by a domain other than our website. We may use third parties&rsquo; cookies and these third parties may include, for example, advertising networks and providers of external services like web traffic analysis services. Please see our cookies table for more information.&nbsp;</p>
                    <p style={{testAlign: 'justify'}}><strong>Session and Persistent cookies</strong></p>
                    <p style={{testAlign: 'justify'}}>Session cookies are cookies which expire once you close your web browser. Persistent cookies are cookies which stay on your device for a set period of time or until you delete them. Please see our cookies table for more information.&nbsp;</p>
                    <p style={{testAlign: 'justify'}}><strong>Cookies control and disabling cookies</strong></p>
                    <p style={{testAlign: 'justify'}}>You can refuse the use of cookies by changing the settings on your browser or by clicking on the cookies consent mechanism. However, if you block certain cookies, such as strictly necessary cookies for the functioning of our website, you may not be able to access all or parts of our website.</p>
                    <p style={{testAlign: 'justify'}}>You can use your browser to delete cookies that have already been stored. However, the steps and measures required vary depending on the browser you use. If you have any questions, please use the Help function or consult the documentation for your browser or contact its creator for support.</p>
                    <p>To find out more about cookies, visit <a href="http://www.aboutcookies.org" target="_blank" rel="noopener noreferrer"><span>www.aboutcookies.org</span></a>&nbsp; or <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer"><span>www.allaboutcookies.org</span></a>.&nbsp;</p>
                    <p style={{testAlign: 'justify'}}><strong>Contact&nbsp;</strong></p>
                    <p style={{testAlign: 'justify'}}>Thank you for reading our Cookie Notice. If you have any queries concerning our use of your personal information, please&nbsp;email&nbsp;<a href="mailto:team@neumind.co.uk" target="_blank" rel="noopener noreferrer"><span>team@neumind.co.uk</span></a></p>
                    <p style={{testAlign: 'justify'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Cookies table&nbsp;</strong></p>
                    <div>
                        <table>
                            <tbody>
                                <tr>
                                    <td><span><strong>Cookie&nbsp;</strong></span></td>
                                    <td><span><strong>Name&nbsp;</strong></span></td>
                                    <td><span><strong>First or third party&nbsp;</strong></span></td>
                                    <td><span><strong>Purpose</strong></span></td>
                                    <td><span><strong>Type</strong></span></td>
                                    <td><span><strong>Expiry&nbsp;</strong></span></td>
                                    <td><span><strong>Links to third party privacy notice&nbsp;</strong></span></td>
                                </tr>
                                <tr>
                                    <td>Cookie Consent</td>
                                    <td>CookieConsent4 (currently but reserve right to increment number)</td>
                                    <td>First party</td>
                                    <td>Determines whether the user has accepted the cookie consent box.&nbsp;</td>
                                    <td>HTTP Cookie</td>
                                    <td>1 year</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Gatsby GDPR Google Tag Manager</td>
                                    <td>gatsby-gdpr-google-tagmanager</td>
                                    <td>First party</td>
                                    <td>This cookie saves your preference with regard to google products.</td>
                                    <td>HTTP Cookie</td>
                                    <td>1 year</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Gatsby scroll position</td>
                                    <td>@@scroll</td>
                                    <td>First party</td>
                                    <td>Records the scroll position on the previous page so that when a user navigates back they will be taken to their last scroll position on that page. Necessary.</td>
                                    <td>HTTP Cookie</td>
                                    <td>Session</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>Google analytics&nbsp;</td>
                                    <td>_ga _ga_#</td>
                                    <td>Third party&nbsp;</td>
                                    <td>These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website.</td>
                                    <td>HTTP Cookie</td>
                                    <td>1 year&nbsp;</td>
                                    <td><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google</a></td>
                                </tr>
                                <tr>
                                    <td>Google Adsense</td>
                                    <td>_gcl_au</td>
                                    <td>Third Party</td>
                                    <td>To store and track conversions.</td>
                                    <td>HTTP Cookie</td>
                                    <td>3 months</td>
                                    <td><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>AWSALBTG</td>
                                    <td>Third Party</td>
                                    <td>Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimise user experience. Necessary.</td>
                                    <td>HTTP Cookie</td>
                                    <td>7 days</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>AWSALBTGCORS</td>
                                    <td>Third Party</td>
                                    <td>Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimise user experience. Necessary.</td>
                                    <td>HTTP Cookie</td>
                                    <td>6 days</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform&nbsp;</td>
                                    <td>__cf_bm</td>
                                    <td>Third Party</td>
                                    <td>This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website. Necessary.</td>
                                    <td>HTTP Cookie</td>
                                    <td>1 day</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>tf_respondent_cc</td>
                                    <td>Third Party</td>
                                    <td>If you accept typeform cookies on an embedded typeform, this stores answers to typeform so you can start where you left off last time.</td>
                                    <td>HTTP Cookie</td>
                                    <td>6 months</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>#-visitorId</td>
                                    <td>Third Party</td>
                                    <td>Pending</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>tf_</td>
                                    <td>Third Party</td>
                                    <td>Pending</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>attribution_user_id</td>
                                    <td>Third Party</td>
                                    <td>Used in context with the website&rsquo;s pop-up questionnaires and messengering. The data is used for statistical or marketing purposes.</td>
                                    <td>HTTP Cookie</td>
                                    <td>1 year</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>#.#-#-#-#-#.ack</td>
                                    <td>Third Party</td>
                                    <td>Used to contain user&rsquo;s survey and quiz answers in Local Storage.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>#.#-#-#-#-#.inProgress</td>
                                    <td>Third Party</td>
                                    <td>Used to contain user&rsquo;s survey and quiz answers in Local Storage.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>#.#-#-#-#-#.queue</td>
                                    <td>Third Party</td>
                                    <td>Used to contain user&rsquo;s survey and quiz answers in Local Storage.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>#.#-#-#-#-#.reclaimEnd</td>
                                    <td>Third Party</td>
                                    <td>Used to contain user&rsquo;s survey and quiz answers in Local Storage.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>#.#-#-#-#-#.reclaimStart</td>
                                    <td>Third Party</td>
                                    <td>Used to contain user&rsquo;s survey and quiz answers in Local Storage.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_anonymous_id</td>
                                    <td>&nbsp;</td>
                                    <td>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_group_id</td>
                                    <td>Third Party</td>
                                    <td>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_group_trait</td>
                                    <td>Third Party</td>
                                    <td>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_page_init_referrer</td>
                                    <td>Third Party</td>
                                    <td>Registers how the user has reached the website to enable pay-out of referral commission fees to partners.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_page_init_referring_domain</td>
                                    <td>Third Party</td>
                                    <td>Registers how the user has reached the website to enable pay-out of referral commission fees to partners.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_session</td>
                                    <td>Third Party</td>
                                    <td>Pending</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_trait</td>
                                    <td>Third Party</td>
                                    <td>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                                <tr>
                                    <td>Typeform</td>
                                    <td>rl_user_id</td>
                                    <td>Third Party</td>
                                    <td>Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.</td>
                                    <td>HTML Local Storage</td>
                                    <td>Persistent</td>
                                    <td><a href="https://admin.typeform.com/to/dwk6gt/" target="_blank" rel="noopener noreferrer">Typeform</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </LayoutBox>
        </Layout>
    );
};

export default CookiesNotice;
